import { indigo, pink } from '@mui/material/colors';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
// import 'clarity';
// import 'pagesense';
import { AccessControlProvider } from 'components/AccessControl';
import { HasPhoneProvider } from 'components/HasPhone';
import { observer } from 'mobx-react-lite';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/Loading';
import { useStores } from './hooks/useStores';
import Routes from './Routes';

const theme = createTheme({
  typography: {
    fontFamily: `Quicksand, Cabin, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
  },
  palette: {
    primary: indigo,
    secondary: pink,
  },
});

function App() {
  const { userStore, commonStore, authStore, statsStore } = useStores();

  useEffect(() => {
    if (commonStore.appLoaded) {
      return;
    }

    if (!commonStore.token) {
      authStore.logout();
      commonStore.setAppLoaded();
      return;
    }

    userStore
      .pullUser()
      .then(() => {
        commonStore.setAppLoaded();
      })
      .catch((err) => {
        console.error(err);
        commonStore.setAppLoadError();
      });
  });

  useEffect(() => {
    if (!commonStore.appLoaded) return;
    if (!window.performance?.getEntriesByType) return;

    const navigationEntries = window.performance.getEntriesByType('navigation');
    if (navigationEntries.length === 0) return;

    const loadTime = window.performance.now() - navigationEntries[0].startTime;
    console.log('LoadTime', loadTime);

    statsStore.timing('sq_web.loadTime', loadTime);
  }, [commonStore.appLoaded, statsStore]);

  if (commonStore.appLoadError) {
    return (
      <div className="snap">
        <h1>App failed to load</h1>
        <p>
          Please check your internet connection. <br />
          <br />
          <button onClick={() => window.location.reload()}>Click here</button> to try refreshing the page
        </p>
      </div>
    );
  }

  if (!commonStore.appLoaded) {
    return <Loading fullpage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <ErrorBoundary>
          <SnackbarProvider>
            <HasPhoneProvider>
              <AccessControlProvider>
                <Routes />
              </AccessControlProvider>
            </HasPhoneProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default observer(App);
