import { fetchTranscodedVideo, fetchBatchStudentTranscodedVideos } from 'api';
import { ApiResponse } from 'apisauce';
import { flow, Instance, types, getEnv } from 'mobx-state-tree';
import { PageStore } from './Page';


export const TranscodedVideo = types.model('TranscodedVideo', {
  transcoded_url: types.maybeNull(types.string),
  thumbnail_url: types.maybeNull(types.string),
  pks: types.string,
  tag: types.string
});

export interface ITranscodedVideo extends Instance<typeof TranscodedVideo> {};

export const TranscodedVideoStore = types
  .model({
    videos: types.array(TranscodedVideo),
    fetchingTranscodedVideos: false,
    transcodedVideoPage: types.maybe(PageStore),
    fetchedVideo: types.maybe(TranscodedVideo)
    
  })
  .actions((self) => ({
    fetchBatchStudentVideos: flow(function* ({ batchStudent, pageNumber }: { pageNumber: number , batchStudent:string}) {
      self.fetchingTranscodedVideos = true;
      try {
        const response: ApiResponse<any> = yield fetchBatchStudentTranscodedVideos({ batchStudent, pageNumber });

        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }

        const { data } = response;
        self.videos = data.results;
        self.transcodedVideoPage = data.page;
      } finally {
        self.fetchingTranscodedVideos = false;
      }
    }),
    fetchTranscodedVideo: flow(function* (pks: string) {
      try {
        self.fetchingTranscodedVideos = true;
        const response: ApiResponse<any> = yield fetchTranscodedVideo({ pks });

        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }

        const { data } = response;
        self.fetchedVideo = data;
      } finally {
        self.fetchingTranscodedVideos = false;
      }
    }),
  }));
