import { types, Instance } from 'mobx-state-tree';
import { Question } from './Question';

export const UnknownDetail = types.model('UnknownDetail', {
  name: types.string,
  activity_type: types.literal('unknown'),
});

///Video
const VideoDeliveries = types
  .model('VideoDeliveries', {
    cloudflare_stream: types.maybe(types.string),
    s3: types.maybe(types.string),
    youtube: types.maybe(types.string),
  })
  .views((self) => ({
    get cloudflareThumbnail() {
      if (!self.cloudflare_stream) return '';
      return 'https://videodelivery.net/' + self.cloudflare_stream + '/thumbnails/thumbnail.jpg?height=270&time=30s';
    },
  }));

export interface IVideoDeliveries extends Instance<typeof VideoDeliveries> {}

const VideoInteraction = types
  .model('VideoInteraction', {
    pause_point_ms: types.integer,
    props: types.frozen({}),
    interaction: types.frozen({ name: '', url: '' }),
    tag: types.maybe(types.string),
  })
  .views((self) => ({
    get isLoadableActivity() {
      return !!self.interaction.url;
    },
  }));

export interface IVideoInteraction extends Instance<typeof VideoInteraction> {}

const Subtitles = types.model('Subtitles', {
  language: types.string,
  label: types.string,
  url: types.string,
});

export interface ISubtitles extends Instance<typeof Subtitles> {}

export const VideoDetail = types.model('VideoDetail', {
  name: types.string,
  activity_type: types.literal('video'),
  delivery_details: VideoDeliveries,
  subtitles: types.array(Subtitles),
  interactions: types.array(VideoInteraction),
  title: types.string,
  description: types.string,
});

export interface IVideoDetail extends Instance<typeof VideoDetail> {}
//End video

export const ReactQuestionDetail = types.model('ReactQuestionDetail', {
  name: types.string,
  activity_type: types.literal('react question'),
  content: types.string,
  answer_type: types.string,
  include_words: types.array(types.string),
  answer_start_phrase: types.string,
  answer_hint: types.string,
});

export interface IReactQuestionDetail extends Instance<typeof ReactQuestionDetail> {}

export const FeedbackQuestion = types.model('FeedbackQuestion', {
  id: types.integer,
  content: types.string,
  answer_type: types.string,
});

export const ReactFeedbackDetail = types.model('ReactFeedbackDetail', {
  name: types.string,
  activity_type: types.literal('react feedback'),
  question: ReactQuestionDetail,
  badges: types.array(types.string),
  feedback_questions: types.array(FeedbackQuestion),
});

export const ReviseQuestionDetail = types.model('ReviseQuestionDetail', {
  name: types.string,
  activity_type: types.literal('revise question'),
  feedback: ReactFeedbackDetail,
});

export const InteractionDetail = types.model('InteractionDetail', {
  name: types.string,
  activity_type: types.literal('interaction activity'),
  title: types.string,
  interaction_props: types.frozen({}),
  interaction: types.frozen({ name: '', url: '' }),
});

export const CodingDetail = types.model('Coding', {
  name: types.string,
  activity_type: types.literal('coding activity'),
  title: types.string,
  loader_token: types.string,
  hint_videos: types.array(VideoDetail),
});

export interface ICodingDetail extends Instance<typeof CodingDetail> {}

export const CodingRecordingDetail = types.model('CodingRecordingDetail', {
  name: types.string,
  activity_type: types.literal('coding recording activity'),
  title: types.string,
  description: types.string,
});

export interface ICodingRecordingDetail extends Instance<typeof CodingRecordingDetail> {}

export const CodingAnnotateDetail = types.model('CodingAnnotateDetail', {
  name: types.string,
  activity_type: types.literal('coding annotate activity'),
  description: types.string,
  template_image_url: types.string,
});

export interface ICodingAnnotateDetail extends Instance<typeof CodingAnnotateDetail> {}

export const GoogleFormDetail = types.model('GoogleFormDetail', {
  name: types.string,
  activity_type: types.literal('google form activity'),
  title: types.string,
  form_url: types.string,
  name_param: '',
  username_param: '',
});

export interface IGoogleFormDetail extends Instance<typeof GoogleFormDetail> {}

export const ScreenShareDetail = types.model('ScreenShareDetail', {
  name: types.string,
  activity_type: types.literal('screen share activity'),
  title: types.string,
  start_url: types.string,
});

export interface IScreenShareDetail extends Instance<typeof ScreenShareDetail> {}

export const FieldSpec = types.model('FieldSpec', {
  type: types.union(types.literal('integer'), types.literal('string')),
  placeholder: types.maybe(types.string),
  options: types.maybe(types.array(types.frozen({ value: '', label: '' }))),
  render_as: types.optional(types.string, 'default'),
});

export const FormField = types
  .model('FormField', {
    id: types.number,
    title: types.string,
    required: types.boolean,
    field_spec: FieldSpec,
  })
  .views((self) => ({
    get name() {
      return 'field.' + self.id;
    },
  }));

export interface IFormField extends Instance<typeof FormField> {}

export const FormDetail = types.model('FormDetail', {
  name: types.string,
  activity_type: types.literal('form activity'),
  title: types.string,
  fields: types.array(FormField),
});

export interface IFormDetail extends Instance<typeof FormDetail> {}

export const HTMLEmbedActivity = types.model('HTMLEmbedActivity', {
  name: types.string,
  activity_type: types.literal('html embed activity'),
  title: types.string,
  html: types.string,
});

export interface IHTMLEmbedActivity extends Instance<typeof HTMLEmbedActivity> {}

export const UnityActivity = types.model('UnityActivity', {
  name: types.string,
  activity_type: types.literal('unity activity'),
  title: types.string,
  html: types.string,
});

export interface IUnityActivity extends Instance<typeof UnityActivity> {}

export const QuizActivity = types.model('QuizActivity', {
  name: types.string,
  activity_type: types.literal('quiz activity'),
  title: types.string,
});

export interface IQuizActivity extends Instance<typeof QuizActivity> {}

export const PollActivity = types.model('PollActivity', {
  name: types.string,
  activity_type: types.literal('poll activity'),
  question: Question,
});

export interface IPollActivity extends Instance<typeof PollActivity> {}

export const RecordingActivityQuestionAsset = types.model('RecordingActivityQuestion', {
  asset_type: types.string,
  is_locked: types.boolean,
  asset_url: types.string,
});

export interface IRecordingActivityQuestionAsset extends Instance<typeof RecordingActivityQuestionAsset> {}

export const RecordingActivityQuestion = types.model('RecordingActivityQuestion', {
  id: types.integer,
  content: types.string,
  sequence: types.integer,
  assets: types.array(RecordingActivityQuestionAsset),
});

export interface IRecordingActivityQuestion extends Instance<typeof RecordingActivityQuestion> {}

export const RecordingActivity = types.model('RecordingActivity', {
  name: types.string,
  activity_type: types.literal('recording activity'),
  questions: types.array(RecordingActivityQuestion),
});

export interface IRecordingActivity extends Instance<typeof RecordingActivity> {}

export const P5JSActivity = types.model('P5JSActivity', {
  name: types.string,
  description: types.string,
  activity_type: types.literal('p5js activity'),
  p5js_base: types.string,
  project_level_name: types.string,
});
export interface IP5JSActivity extends Instance<typeof P5JSActivity> {}

export const ActivityRecordingActivity = types.model('ActivityRecordingActivity', {
  name: types.string,
  description: types.string,
  title: types.string,
  activity_type: types.literal('activity recording activity'),
  activity: types.frozen(),
});
export interface IActivityRecordingActivity extends Instance<typeof ActivityRecordingActivity> {}

export const IframeRecordingActivityDetail = types.model('IframeRecordingActivity', {
  name: types.string,
  title: types.string,
  activity_type: types.literal('iframe recording activity'),
});
export interface IIframeRecordingActivity extends Instance<typeof IframeRecordingActivityDetail> {}

export const VoiceChatActivity = types.model('VoiceChatActivity', {
  activity_uuid: types.string,
  name: types.string,
  title: types.string,
  activity_type: types.literal('Voice Chat activity'),
  activity_context: types.frozen({
    ai_name: '',
    activity_question: '',
    ai_avatar: '',
    initial_message: { audio_url: '', content: '' },
  }),
});

export interface IVoiceChatActivity extends Instance<typeof VoiceChatActivity> {}

export const ConversationalPoint = types.model('ConversationalPoints', {
  name: types.string,
  pause_point_ms: types.number,
  question: types.string,
});

export interface IConversationalPoint extends Instance<typeof ConversationalPoint> {}

export const ConversationRecordingActivity = types.model('ConversationRecordingActivity', {
  name: types.string,
  title: types.string,
  activity_type: types.string,
  activity_uuid: types.string,
  theme: types.string,
  video: VideoDetail,
  interactions: types.array(ConversationalPoint),
});

export interface IConversationRecordingActivity extends Instance<typeof ConversationRecordingActivity> {}

export const HTMLQuestionActivityDetail = types.model('HTMLQuestionActivityDetail', {
  name: types.string,
  activity_type: types.literal('html question activity'),
  activity_url: types.string,
  category: types.string,
  title: types.maybeNull(types.string),
  activity_uuid: types.string,
});

export interface IHTMLQuestionActivityDetail extends Instance<typeof HTMLQuestionActivityDetail> {}

export const PhotoCaptureActivityDetail = types.model('PhotoCaptureActivity', {
  name: types.string,
  activity_type: types.literal('photo capture activity'),
  title: types.maybeNull(types.string),
  activity_uuid: types.string,
  description: types.maybeNull(types.string),
});

export interface IPhotoCaptureActivityDetail extends Instance<typeof PhotoCaptureActivityDetail> {}

export const FileUploadActivityDetail = types.model('FileUploadActivity', {
  name: types.string,
  activity_type: types.literal('file upload activity'),
  title: types.maybeNull(types.string),
  activity_uuid: types.string,
  description: types.maybeNull(types.string),
  file_type: types.union(types.literal('image'), types.literal('pdf')),
});

export interface IFileUploadActivityDetail extends Instance<typeof FileUploadActivityDetail> {}

export const ActivityDetail = types.union(
  VideoDetail,
  ReactQuestionDetail,
  ReactFeedbackDetail,
  ReviseQuestionDetail,
  InteractionDetail,
  CodingDetail,
  CodingRecordingDetail,
  CodingAnnotateDetail,
  GoogleFormDetail,
  FormDetail,
  ScreenShareDetail,
  HTMLEmbedActivity,
  UnityActivity,
  QuizActivity,
  PollActivity,
  RecordingActivity,
  P5JSActivity,
  ActivityRecordingActivity,
  IframeRecordingActivityDetail,
  VoiceChatActivity,
  ConversationRecordingActivity,
  HTMLQuestionActivityDetail,
  PhotoCaptureActivityDetail,
  FileUploadActivityDetail,
  UnknownDetail
);

export interface IActivityDetail extends Instance<typeof ActivityDetail> {
  name: string;
  activity_type: string;
}
