import { types, Instance } from 'mobx-state-tree';
import InvokerType from './InvokerType';

export const PersistedReactionsStore = types
  .model('PersistedReactionsStore', {
    enableReactions: false,
    addNewReaction: InvokerType,
    removeReactionInvoker: InvokerType,
    reactionsMap: types.map(types.string),
  })
  .actions((self) => ({
    pushNewReaction: (params: { reaction: string; username: string }) => {
      return self.addNewReaction.invoke(params);
    },
    removeReaction: (username: string) => {
      return self.removeReactionInvoker.invoke({ username });
    },
    toggleFeedbackReaction() {
      self.enableReactions = !self.enableReactions;
    },
    hideFeedbackReaction() {
      self.enableReactions = false;
    },
    showFeedbackReaction() {
      self.enableReactions = true;
    },
  }));

export interface IPersistedReactionsStore extends Instance<typeof PersistedReactionsStore> {}
