import {
  fetchBatchStudentProjects,
  fetchMyProjects,
  fetchStudentCodingActivity,
  fetchStudentProjectLink,
  fetchStudentProjects,
} from 'api';
import { ApiResponse } from 'apisauce';
import { types, flow, Instance, getEnv, IAnyModelType, cast } from 'mobx-state-tree';
import { formatDate } from 'utils/formatDate';
import { PageStore } from './Page';
import User, { Student } from './User';
import { BatchLite } from './coachStores/BatchStore';
import {
  SubmissionStatus,
  fetchProjectSubmissions,
  fetchProjectSubmissionsNew,
  getProjectSubmission,
  submitProjectFeedback,
} from 'api/coachDashboard';
import { parse } from 'fecha';
import Config from 'config';

// late import to avoid circular dependencies
const StudentActivity = types.late(() => require('./StudentActivity').StudentActivity);
const ActivityDetail = types.late(() => require('./ActivityDetail').ActivityDetail);

export const ProjectLite = types.model('ProjectLite', {
  title: types.string,
  image_url: types.maybeNull(types.string),
  name: types.string,
});

export const Project = types
  .model({
    project_created_at: types.maybeNull(types.string),
    name: types.string,
    playlist_name: types.string,
    title: types.string,
    image_url: types.maybeNull(types.string),
  })
  .views((self) => ({
    get ProjectCreatedAt() {
      if (self.project_created_at == null) return '';
      return formatDate(self.project_created_at, 'hh:mm A, MMM D');
    },
  }));

export interface IProject extends Instance<typeof Project> {}

export const ProjectSubmissionNew = types
  .model({
    name: types.string,
    status: types.string,
    created_at: types.string,
    project: ProjectLite,
    student: types.maybeNull(User),
  })
  .views((self) => ({
    get createdAt() {
      return parse(self.created_at, Config.DATETIME_FORMAT);
    },
    get createdAtFormatted() {
      return formatDate(self.created_at, 'hh:mm A, MMM D');
    },
  }));

const ProjectSubmissionFeedback = types
  .model({
    coach: User,
    comment: types.string,
    created_at: types.string,
  })
  .views((self) => ({
    get createdAtFormatted() {
      if (self.created_at == null) return '';
      return formatDate(self.created_at, 'hh:mm A, MMM D');
    },
  }));

const ProjectSubmissionExtra = ProjectSubmissionNew.named('ProjectSubmissionExtra').props({
  submission_feedback: types.maybeNull(ProjectSubmissionFeedback),
  answer: StudentActivity
});

export interface IProjectSubmissionExtra extends Instance<typeof ProjectSubmissionExtra> {}

export function ProjectSubmissionLate(): IAnyModelType {
  return types
    .model('ProjectSubmission', {
      created_at: types.string,
      name: types.string,
      student: Student,
      batch: types.maybeNull(BatchLite),
      coach: types.maybeNull(User),
      project_image: types.maybe(types.string),
      feedback: types.maybeNull(types.string),
      status: types.string,
      ai_feedback: types.maybeNull(types.string),
      submitting: false,
      external_project_url: types.maybeNull(types.string),
    })
    .views((self) => ({
      get feedbackSubmitted() {
        return Boolean(self.feedback);
      },
      get createdAt() {
        return parse(self.created_at, Config.DATETIME_FORMAT);
      },
    }));
}

export interface IProjectSubmission extends Instance<ReturnType<typeof ProjectSubmissionLate>> {}

export function ProjectActivityLate(): IAnyModelType {
  return types.compose(
    ProjectSubmissionLate(),
    types.model({
      answer: StudentActivity,
      activity: ActivityDetail,
    })
  );
}

export interface IProjectActivity extends Instance<ReturnType<typeof ProjectActivityLate>> {}

export const ProjectStore = types
  .model({
    projects: types.array(Project),
    fetchingProjects: false,
    projectsPage: types.maybe(PageStore),
    loading: false,
    submissions: types.array(types.late(ProjectSubmissionLate)),
    fetchingcurrentActivity: false,
    currentActivity: types.maybe(types.late(ProjectActivityLate)),
    newProjectSubmissions: types.array(ProjectSubmissionExtra),
    newProjectSubmissionsPage: types.maybe(PageStore),
  })
  .actions((self) => ({
    fetchProjects: flow(function* ({ page }: { page: number }) {
      self.fetchingProjects = true;
      try {
        const response: ApiResponse<any> = yield fetchMyProjects({ page });

        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }

        const { data } = response;
        self.projects = data.results;
        self.projectsPage = data.page;
      } finally {
        self.fetchingProjects = false;
      }
    }),
    fetchProjectSubmissionsNew: flow(function* ({
      studentUsername,
      projectName,
      pageNumber = 1,
      submissionStatus,
      batchName
    }: {
      studentUsername?: string;
      projectName?: string;
      pageNumber?: number;
      submissionStatus?: SubmissionStatus;
      batchName?: string
    }) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchProjectSubmissionsNew({
          studentUsername,
          projectName,
          pageNumber,
          batchName,
          submissionStatus,
        });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.newProjectSubmissions = cast(response.data.results);
        self.newProjectSubmissionsPage = cast(response.data.page);
      } finally {
        self.loading = false;
      }
    }),
    fetchBatchStudentProjects: flow(function* (
      batchStudent: string,
      daysBefore: number,
      pageNumber: number,
      pageSize?: number
    ) {
      try {
        self.fetchingProjects = true;
        const response: ApiResponse<any> = yield fetchBatchStudentProjects({
          batchStudent,
          daysBefore,
          pageNumber,
          pageSize,
        });

        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }

        const { data } = response;
        self.projects = data.results;
        self.projectsPage = data.page;
      } finally {
        self.fetchingProjects = false;
      }
    }),
    fetchCodingActivity: flow(function* (studentUsername: string, projectName: string) {
      const response: ApiResponse<any> = yield fetchStudentCodingActivity({ studentUsername, projectName });

      if (response.problem) {
        return;
      }
      const { data } = response;
      return data;
    }),
    fetchProjectLink: flow(function* (studentUsername: string, projectName: string) {
      self.loading = true;
      const response: ApiResponse<any> = yield fetchStudentProjectLink({ studentUsername, projectName });
      self.loading = false;
      const { data } = response;
      return data;
    }),
    fetchStudentProjects: flow(function* ({
      studentUsername,
      daysBefore = 0,
      pageNumber,
      pageSize,
    }: {
      studentUsername: string;
      daysBefore?: number;
      pageNumber: number;
      pageSize: number;
    }) {
      try {
        self.fetchingProjects = true;
        const response: ApiResponse<any> = yield fetchStudentProjects({ studentUsername, pageNumber, pageSize });

        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }

        const { data } = response;
        self.projects = data.results;
        self.projectsPage = data.page;
      } finally {
        self.fetchingProjects = false;
      }
    }),
    fetchSubmission: flow(function* ({
      studentUsername,
      projectName,
      pageNumber,
      pageSize,
    }: {
      studentUsername: string;
      projectName: string;
      pageNumber: number;
      pageSize: number;
    }) {
      try {
        self.fetchingProjects = true;
        const response: ApiResponse<any> = yield fetchProjectSubmissions({
          studentUsername,
          projectName,
          pageNumber,
          pageSize,
        });

        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }

        const { data } = response;
        self.submissions = data.results;
        self.projectsPage = data.page;
      } finally {
        self.fetchingProjects = false;
      }
    }),
    submitFeedback: flow(function* ({
      feedback,
      submissionName,
      status,
    }: {
      feedback: string;
      submissionName: string;
      status: string;
    }) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield submitProjectFeedback({
          feedback,
          submissionName,
          status,
        });

        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        return true;
      } finally {
        self.loading = false;
      }
    }),
    getSubmission: flow(function* (submission: string) {
      self.fetchingcurrentActivity = true;
      try {
        const response: ApiResponse<any> = yield getProjectSubmission({ submission });

        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }

        self.currentActivity = response.data as IProjectActivity;
      } finally {
        self.fetchingcurrentActivity = false;
      }
    }),
  }));
